import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { button, helperText, icon, palette, typography } from "theme";

const Provider = ({ children }) => {
  const theme = createTheme({
    palette,
    typography,
    components: {
      ...icon,
      ...button,
      ...helperText,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
    </ThemeProvider>
  );
};

export default React.memo(Provider);
