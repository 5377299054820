import AlertModal from "components/modals/AlertModal";
import BlindReason from "components/modals/BlindReason";
import ConfirmModal from "components/modals/ConfirmModal";
import ImageModal from "components/modals/ImageModal";
import { action as modalAction } from "modules/modal";
import { useDispatch, useSelector } from "react-redux";
import IconAlert from "stories/v2/modal/AlertModal";
import IconConfirm from "stories/v2/modal/ConfirmModal";

const useModal = () => {
  const dispatch = useDispatch();
  const list = useSelector(state => state.modal.list);

  const add = (component, options, resolve) => {
    dispatch(modalAction.add({ component, options, resolve }));
  };

  const close = modal => {
    dispatch(modalAction.close(modal));
  };

  const clear = () => {
    dispatch(modalAction.clear());
  };

  const optionToObject = options => {
    return typeof options === "object" ? options : { title: options };
  };

  return {
    list,
    close,
    clear,
    alert: options =>
      new Promise(resolve => {
        add(
          AlertModal,
          { ...optionToObject(options), isBlockEnter: true, isBlockEsc: true },
          resolve
        );
      }),
    alertV2: options =>
      new Promise(resolve => {
        add(
          IconAlert,
          { ...optionToObject(options), isBlockEnter: true, isBlockEsc: true },
          resolve
        );
      }),
    infoAlert: options =>
      new Promise(resolve => {
        add(
          IconAlert,
          {
            ...optionToObject({ ...options, icon: options.icon ?? "info" }),
            isBlockEnter: true,
            isBlockEsc: true,
          },
          resolve
        );
      }),
    confirm: options =>
      new Promise(resolve => {
        add(ConfirmModal, optionToObject(options), resolve);
      }),
    confirmV2: options =>
      new Promise(resolve => {
        add(IconConfirm, optionToObject(options), resolve);
      }),
    warningConfirm: options =>
      new Promise(resolve => {
        add(IconConfirm, optionToObject({ ...options, icon: "warning" }), resolve);
      }),
    errorConfirm: options =>
      new Promise(resolve => {
        add(IconConfirm, optionToObject({ ...options, icon: "error" }), resolve);
      }),
    image: (images, index) =>
      new Promise(resolve => {
        add(ImageModal, optionToObject({ images, index }), resolve);
      }),
    blindReason: reason =>
      new Promise(resolve => {
        add(BlindReason, optionToObject({ reason }), resolve);
      }),
    custom: ({ component, options }) =>
      new Promise(resolve => {
        add(component, optionToObject(options), resolve);
      }),
  };
};

export default useModal;
